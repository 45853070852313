/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&display=swap');
@import './scss/material';
@import './scss/utils';
@import './scss/responsive';
@import './scss/variables';
@import './scss/theme';
@import "~bootstrap/dist/css/bootstrap.css";


html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: 'Noto Sans', sans-serif !important; 
}

.mat-toolbar.mat-primary {
    background: #19355f !important;
    color: white;
}

.mat-drawer {
    background-color: #1b2133 !important;
    color: white !important;
}

.mat-list-base .mat-list-item {
    color: white !important;
}

.color-text-success{
    color: rgb(39, 220, 39) !important
}

.color-texta-warn{
    color: rgb(248, 68, 3)
}
.color-text-error{
    color: red
}
.color-text-primary{
    color: blue
}




@media screen and (min-width: 768px) {
    .mat-tab-body-content {
        height: 100%;
        overflow: hidden !important;
        width: 100%;
    }
}